// extracted by mini-css-extract-plugin
export var actionBar = "AdminOrders__actionBar__G3ESb";
export var actionBarDropdown = "AdminOrders__actionBarDropdown__MDfBL";
export var actionBarError = "AdminOrders__actionBarError__deBIB";
export var actionBarInfo = "AdminOrders__actionBarInfo__sllsM";
export var actionBarInner = "AdminOrders__actionBarInner__wTyjQ";
export var actionBarSpinner = "AdminOrders__actionBarSpinner__JqOB_";
export var avatar = "AdminOrders__avatar__or0D_";
export var cancelled = "AdminOrders__cancelled__zwj82";
export var cardInfo = "AdminOrders__cardInfo__LOsPH";
export var changing = "AdminOrders__changing__yDY3l";
export var checkbox = "AdminOrders__checkbox__YxvaJ";
export var column = "AdminOrders__column__dhBDx";
export var company = "AdminOrders__company__qdVuN";
export var container = "AdminOrders__container__Qnu2Z";
export var contentWrapper = "AdminOrders__contentWrapper__jf1Mh";
export var controlRightAlign = "AdminOrders__controlRightAlign__luTCw";
export var dataRow = "AdminOrders__dataRow__Yn1df";
export var designation = "AdminOrders__designation__p0onE";
export var details = "AdminOrders__details__SaPdM";
export var dot = "AdminOrders__dot__IADLT";
export var dropdown = "AdminOrders__dropdown__LhrkE";
export var dropdownBody = "AdminOrders__dropdownBody__KOBvI";
export var dropdownBodyRow = "AdminOrders__dropdownBodyRow__X16em";
export var email = "AdminOrders__email__mFxMR";
export var exportToCsvButton = "AdminOrders__exportToCsvButton__V_gfh";
export var filters = "AdminOrders__filters__tQapF";
export var finalized = "AdminOrders__finalized__hFdUk";
export var flex = "AdminOrders__flex__EIZA9";
export var flexColumn = "AdminOrders__flexColumn__AfgBq";
export var gap1 = "AdminOrders__gap1__EErIC";
export var gap2 = "AdminOrders__gap2__LKTlo";
export var gap3 = "AdminOrders__gap3__U0qVr";
export var gap4 = "AdminOrders__gap4__W_inN";
export var gap5 = "AdminOrders__gap5__GW3yc";
export var headerControls = "AdminOrders__headerControls__XokIJ";
export var hidden = "AdminOrders__hidden__fNY_q";
export var icon = "AdminOrders__icon__jgV_7";
export var iconClose = "AdminOrders__iconClose__yYNAl";
export var iconSort = "AdminOrders__iconSort__U4ty8";
export var id = "AdminOrders__id__ypmCd";
export var inProgress = "AdminOrders__inProgress__iAjaa";
export var label = "AdminOrders__label__Z4wLr";
export var link = "AdminOrders__link__M02wv";
export var linkCancel = "AdminOrders__linkCancel__y7Y9x";
export var name = "AdminOrders__name__pnKF7";
export var nav = "AdminOrders__nav__J4SGY";
export var noRecordCard = "AdminOrders__noRecordCard__OpsjH";
export var notificationBar = "AdminOrders__notificationBar__gvOfb";
export var notificationBarContainer = "AdminOrders__notificationBarContainer__uP74P";
export var notificationBarError = "AdminOrders__notificationBarError__BTgjQ";
export var notificationBarInner = "AdminOrders__notificationBarInner__ftmyx";
export var orderDataview = "AdminOrders__orderDataview__u8CMp";
export var orderDataviewHeader = "AdminOrders__orderDataviewHeader__wiXDe";
export var paginationBar = "AdminOrders__paginationBar__l441I";
export var paginationInfo = "AdminOrders__paginationInfo__tMGgG";
export var phone = "AdminOrders__phone__Pz7JI";
export var pointer = "AdminOrders__pointer__nUGG7";
export var product = "AdminOrders__product__OEBgc";
export var provider = "AdminOrders__provider__XUdi4";
export var row = "AdminOrders__row__ElsgQ";
export var searchField = "AdminOrders__searchField__MwfGf";
export var searchIcon = "AdminOrders__searchIcon__oGzyv";
export var shown = "AdminOrders__shown__VEIS1";
export var status = "AdminOrders__status__leO9v";
export var statusDropdown = "AdminOrders__statusDropdown__lKQHH";
export var subTitle = "AdminOrders__subTitle__W7uns";
export var tableHeaderCell = "AdminOrders__tableHeaderCell__Jh75d";
export var title = "AdminOrders__title__IJlzg";
export var titleMain = "AdminOrders__titleMain__emnU9";
export var user = "AdminOrders__user__MmOwA";
export var userDropdown = "AdminOrders__userDropdown__hBiN0";